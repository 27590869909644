import api from '@/services/api';
export default
{
    async generarCookie(){
        let url          = `/api/sanctum/csrf-cookie`;
        let response     = await api({
            url: url,
            method: 'get',
            withCredentials:true,
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },

        });
        return response;
    },
    async validarToken(formData){
        let url          = `/api/validate-token`;
        let response     = await api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    async getExamenAvanza(uuid_examen){
        let url          = `/api/get-examen-vista-previa/${uuid_examen}`;
        let response   = await api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    async verificarExamenesUserId(user_id){
        let url          = `/api/verificar-examenes-user/${user_id}`;
        let response   = await api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },





    storeRespuestaColumna(formData){
        let url          = `/api/examenes/storeRespuestaColumna`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },

    finalizarExamenByUser(formData){
        let url          = `/api/examenes/finalizarExamenByUser`;
        //let url          = `/api/finalizarExamenByUser`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },

    verificarCalificacionUser(formData){
        let url          = `/api/examenes/verificar-calificacion-alumno`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },


    setIniciarExamenAlumno(formData){
        let url          = `/api/examenes/storeIniciarExamenAlumno`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },

    getverificarTiempoExamen(uuid_examen){
        let url          = `/api/examenes/getverificarTiempoExamen/${uuid_examen}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    storeTiempoActualizado(formData){
        let url          = `/api/examenes/storeTiempoActualizado`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    storeRespuestaCheckbox(formData){
        let url          = `/api/examenes/storeRespuestaCheckbox`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    storeRespuestaOrdenamiento(formData){
        let url          = `/api/examenes/store-respuesta-ordenamiento`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    storeRespuestaCategorizacion(formData){
        let url          = `/api/examenes/store-respuesta-categorizacion`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    storeRespuestaRadio(formData){
        let url          = `/api/examenes/storeRespuestaRadio`;
                                  let response     = api({
                                      url: url,
                                      method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    getAllPreguntasRespuestasSimulacroV2(uuid_examen){
        //let url          = `/api/examenes/all-preguntas-respuestas-v2/${uuid_examen}`;
        let url          = `/api/all-preguntas-respuestas-simulacro-v2/${uuid_examen}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getAllPreguntasRespuestasV2(uuid_examen){
        let url          = `/api/examenes/v2/all-preguntas-respuestas/${uuid_examen}`;
        //let url          = `/api/all-preguntas-respuestas-v2/${uuid_examen}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getAllPreguntasRespuestas(uuid_examen){
        let url          = `/api/examenes/all-preguntas-respuestas/${uuid_examen}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getExamUser(formData){
        let url          = `/api/examenes/storeExam`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },

    storeExam(formData){
        let url          = `/api/examenes/storeExam`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    storePreguntaExamen(formData){
        let url          = `/api/examenes/store-pregunta-examen`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    getExamenes()
    {
        let url          = `/api/examenes/all`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

}