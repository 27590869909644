<template fluid>
  <b-container>
    <b-row>
      <b-col cols="12">
            <h1>Welcome</h1>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "simple",
  display: "Simple",
  order: 0,

  data() {
    return {
      enabled: true,

      FormColumnaA: {
        select:[],
        visibleModalEliminarPregunta:false,
        uuid_reactivo_fk: null,
        mensaje_confirmacion: null,
        uuid_columnaA: 0,
        descripcion: null,
        uuid_columnaB_respuesta: null,
        visibleModalConfirmacion: false,
        data: [
          { name: "Escribe el correo que enviarás", uuid_columnaA: "100",id:1,uuid_columnaB_fk:"17890" },
          { name: "Da clic en el botón de Redactar", uuid_columnaA: "200",id:2,uuid_columnaB_fk:"17890" },
          { name: "Abrir gmail", uuid_columnaA: "300",id:3,uuid_columnaB_fk:"37890" },
          { name: "Da clic en el botón enviar", uuid_columnaA: "400",id:4,uuid_columnaB_fk:"37890" },
          { name: "Llena los datos de destinatario y asunto", uuid_columnaA: "500",id:5,uuid_columnaB_fk:"57890" },
        ],
        dataSelect:[
          {uuid_columnaB:"7890", opcion:"concepto 1"},
          {uuid_columnaB:"17890", opcion:"concepto 2"},
          {uuid_columnaB:"27890", opcion:"concepto 3"},
          {uuid_columnaB:"37890", opcion:"concepto 4"},
          {uuid_columnaB:"47890", opcion:"concepto 5"},
          {uuid_columnaB:"57890", opcion:"concepto 6"},
        ],
        disabledBtns: false,
        fields: [
          {label: 'Pregunta', key: 'name', class: 'text-left', width:100},
          {label: 'Respuesta', key: 'concepto', class: 'text-center'},
          {label: 'Acciones', key: 'actions', class: 'text-center'}
        ],
        currentPage: 1,
        perPage: 5,
        filterOn: [],
        totalRows: 0,
        filter: null,
        textoBtn: null
      },
      dragging: false
    };
  },
  mounted(){

  },
  computed: {

  },
  methods: {

  }
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>