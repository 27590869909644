<template>
  <b-container fluid>
    <b-modal id="modalEsperarProcesando" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             title="¡Atención!"
             v-model="modalEsperar"
    >
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="success" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¡Favor de esperar,procesando información...!
        </b-alert>
      </b-card>
    </b-modal>

    <!--    <div id="examenFinalizaTiempo" v-if="this.$store.getters.usuario.uuid_examen_permitido!=uuid_examen" style="margin-top: 50px;">
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <b-card>
                  <h5>¡No tienes permisos para este examen.!
                  </h5>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </div>-->


    <div id="concentradoExamenes" style="margin-top: 50px;">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" style="text-align: center">
            <h2><span style="color: #952f81">Concentrado de Exámenes-Avanza</span></h2>
          </b-col>
        </b-row>
        <!--        <b-row>
                  <b-col cols="12">
                    <b-card>
                      <h5><span style="color: #952f81">A continuación se enlistan las respuestas que tuviste correctas:</span></h5>
                    </b-card>
                  </b-col>
                </b-row>-->
        <b-row>
          <b-col cols="12">
            <div id="examenes" v-for="(examen, indexExamen) in examenes" :key="indexExamen" class="mb-4">
              <b-row>
                <b-col cols="12">

                  <h3><b>Examen:{{examen.titulo_examen}}</b></h3>
                  <p><b>Email:{{examen.email_user}}</b></p>
                  <p><b>Calificación:{{examen.calificacion_obtenida.toFixed(2)}}</b></p>

                  <div id="respuestasFinales" v-for="(question, index) in examen.preguntas" :key="index"
                       class="mb-4">
                    <b-row>
                      <b-col cols="12">
                        <b-card>
                          <b-row>
                            <h3>
                              <div v-html="question.pregunta"
                                   style="font-family: sans-serif;font-size: 16px;line-height: 1.5;margin-bottom: 10px"></div>
                            </h3>
                          </b-row>
                          <div id="respuestaOpciones" v-for="(respuestaConcentrado, index) in question.respuestas"
                               :key="index" class="mb-4">
                            <b-row>

                              <div v-html="respuestaConcentrado.respuesta"
                                   style="font-family: 'Roboto', sans-serif;font-size: 16px;line-height: 1.5;"></div>

                              <!--icono ok-->
                              <div id="calificacionRespuesta">
                                <div id="respuestaOk" v-if="respuestaConcentrado.correcta===1">

                                  <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                            title="Respuesta correcta" class="ml-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                    </svg>
                                  </b-button>
                                </div>

                                <div id="respuestaIncorrect" v-if="respuestaConcentrado.correcta===0">

                                  <b-button variant="danger" v-b-modal.modal-prevent-closing size="sm"
                                            title="Respuesta correcta" class="ml-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                    </svg>
                                  </b-button>
                                </div>
                              </div>




                            </b-row>
                          </div>
                        </b-card>

                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </div>

  </b-container>
</template>

<script>
import ExamenService from '@/services/ExamenService';


export default {
  components: {},
  data() {
    return {
      modalEsperar:false,
      examenes: [],
      disabledRespuesta: false,
      clave_uuid_examen: null,
      disabledBtnIniciarExamen: true,
      calificacionFinal: null,
      concentradoCalificaciones: null,
      modalFaltanPreguntas: false,
      respuestasOrdenamiento: [],
      activeNames: [],
      respuestaTipo5: [],
      respuestasColumna: [],
      concentradoRespuestasPorColumna: [],
      respuestasCategorizacion: {}, // Nuevo objeto para almacenar las respuestas arrastradas
      //para reactivo de categorizacion
      categoriasArray: [],
      opcionesArray: [], // Aquí almacenaremos los elementos de opciones
      categorias: [],    // Aquí almacenaremos las categorías y sus opciones
      //respuestas: [],
      nombres_categorias: [],
      //fin reactivo de categorizacion


      examenFinalizadoUser: false,
      modalEliminarPregunta: false,
      modalConfirmacionEliminarPregunta: false,
      disabledBtnsEliminarPregunta: false,
      respuestaTipo1: [],
      respuestaTipo2: [],
      respuestaTipo3: [],
      respuestaTipo6: [],
      mensajeFinalizadoPorTiempo: false,
      selected: null,
      reactivos_fields: [
        {label: 'Descripcion', key: 'descripcion', class: 'text-left'},
        {label: 'Concepto', key: 'concepto', class: 'text-left'},
      ],
      currentPage_reactivos: 1,
      perPage_reactivos: 20,
      filter_reactivos: null,
      filterOn_reactivos: [],
      modalConfirmacionImagen: false,
      mensajeConfirmacion: null,
      examenActivo: false,
      user_id_analize: (this.$route.params.user_id_analize) ? (this.$route.params.user_id_analize) : 0,
      preguntas: [],
      respuestas: [],
      selectedOptions: [], // Respuestas seleccionadas por el usuario
      checked: [], // Respuestas seleccionadas por el usuario
      btnFinalizarexamen: false,
      totalTime: 10800, // Tiempo total en segundos
      timeRemaining: 10800, // Tiempo restante en segundos
      intervalId: null, // ID del intervalo para controlar el contador
      isRunning: false, // Estado de ejecución del contador

      //variables del examen
      examen_finalizado: 0,
      mostrar_resultados: 0,

      estatusExamen: null,
      mostrarRecomendacion: true,
      counter: 0,
      interval: null,
      tiempoExamen: null,
    }
  },
  mounted() {
    this.verificarExamenesUserId()
  },

  computed: {
    formatTime() {
      const minutes = Math.floor(this.timeRemaining / 60);
      const seconds = this.timeRemaining % 60;
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
  },
  methods: {
    async verificarCalificacionStudent() {
      let formData = new FormData();
      this.disabledBtnsEliminarPregunta = true
      formData.append("uuid_examen_fk", this.clave_uuid_examen);
      const response = await ExamenService.verificarCalificacionUser(formData);
      let {
        success,
        estatusExamen,
        calificacionFinal,
        concentradoCalificaciones

      } = response.data;
      if (success) {
        this.modalEliminarPregunta = false
        if (estatusExamen == 3) {
          this.mostrarRecomendacion = false
          this.mensajeFinalizadoPorTiempo = false
          this.examenActivo = false
          this.examenFinalizadoUser = true
          this.calificacionFinal = calificacionFinal.toFixed(2)
          this.concentradoCalificaciones = concentradoCalificaciones
          //desplegar mensaje lo sentimos
        } else {
          /*          console.log("response data")
                    console.log(response.data)*/
          this.modalFaltanPreguntas = true
        }
      }
    },
    async verificarExamenesUserId() {/*
      let formData = new FormData();
      formData.append("user_id", this.user_id_analize);*/
      this.modalEsperar = true
      const response = await ExamenService.verificarExamenesUserId(this.user_id_analize);
      let {
        success,
        examenesConcentrado
      } = response.data;
      if (success) {
        this.modalEsperar = false
        console.log("examenes concentrado")
        console.log(response.data.examenesConcentrado)
        this.examenes = examenesConcentrado
      }
    },


  },

}


</script>
<style>
/*@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,500;1,900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,400;0,500;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,400;0,500;1,900&family=Sanchez&display=swap');

.b-form-radio + .b-form-radio {
  margin-top: 1rem;
}

.espacio-contador {
  display: block;
}

.container-contador-home {
  position: relative;
}

.container-contador {
  position: fixed;
  z-index: 9999;
  padding: 10px;
  top: 6.9vh;
  right: 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  padding: 100px;
}

.fixed-div {
  position: fixed;
  top: 75px;
  left: 250px;
  background-color: red;
  padding: 10px;

}

.fixedEdit-div {
  position: fixed;
  top: 75px;
  left: 250px;

  padding: 10px;

}

.float {
  position: fixed;
  width: 160px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  /*background-color: #952f81;*/
  background-color: #952f81;
  color: #FFF;
  border-radius: 5px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.multiline-content {
  white-space: pre-line;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.bordered-col {
  border: 1px solid #ccc;
  padding: 10px; /* Opcional: Añade espaciado interno para que el contenido no quede pegado al borde */
}

.column {
  border: 1px solid #ddd;
  background-color: #f0f0f0;
  padding: 10px;
  margin: 10px;
  width: 250px;
}
</style>