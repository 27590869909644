import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from "../views/Home.vue";


//Adding layouts router.

const Layout1 = () => import("@/layouts/backend/Layout-1")

//Adding page content router.
//const Dashbord1 = () => import('@/views/backend/Dashboards/Dashbord1')

//Creador de Agenda v1 19-02-2023


import opcionMultipleUnaRespuesta from "@/views/Reactivos/opcionMultipleUnaRespuesta";
import reactivoVariasRespuestas from "@/views/Reactivos/reactivosVariasRespuestas";
import ordenamiento from "@/views/Reactivos/reactivos-ordenamiento";
import reactivoCategorizacion from "@/views/Reactivos/reactivo-categorizacion";
import dashboardPreguntas from "@/views/Reactivos/dashboardReactivos";
import reproductorMp3 from "@/views/PoC/reproductormp3";

//Examenes
import dashboardExamenes from "@/views/Examenes/dashboardExamenes";
import crearExamen from "@/views/Examenes/crearExamen";
//import vistaexamenalumno from "@/views/front/vistaexamenalumno";
//import examen from "@/views/front/examenxxx";
import examen from "@/views/front/examen";
import examenv2 from "@/views/front/examenv2";
import examenv2Test from "@/views/front/examenv2-test";
import examenNivelando from "@/views/front/examen";
import coundown from "@/views/front/coundown";

import noAutorizado from "@/views/front/noAutorizado";
import sinExamen from "@/views/front/sin-examen";
import examenAvanzaNivelando from "@/views/front/examen-avanza-nivelando";

import pocReactivoOrdenamiento from "@/views/PoC/reactivos-ordenamiento";
import pocBSelect from "@/views/PoC/b-select";
import reactivosColumnas from "@/views/PoC/reactivos-columnas";
import reactivosCategorizacionColumnas from "@/views/PoC/reactivos-categorizacion-columnas.vue";
//import reactivosVerdaderoFalso from "@/views/PoC/reactivo-verdadero-falso";
import reactivoVerdaderoFalso from "@/views/Reactivos/verdaderoFalso";
import reactivoRelacionarColumnas from "@/views/Reactivos/relacionarColumnas";
import dashboardAsignaciones from "@/views/Asignacion/dashboardAsignaciones";
import dashboardGrupos from "@/views/Grupos/dashboardGrupos";
import dashboardImportaciones from "@/views/Importaciones/dashboardImportaciones";
import welcomeAprende from "@/views/PoC/welcomeAprende";
import pocOperacionesArreglos from "@/views/PoC/operacionesArreglos";
import reactivoCategorizacionReload from "@/views/PoC/reactivo-categorizacion-reload-respuestas.vue";
import reporteExamen from "@/views/front/reporte-examenes";
Vue.use(VueRouter)

const childRoute = () => [
  {
    path:"/poc-operaciones-arreglos",
    name:"pocOperacionesArreglos",
    component:pocOperacionesArreglos

  },
  {
    path:"/poc-reactivo-categorizacion/:examen",
    name:"reactivoCategorizacionReload",
    component:reactivoCategorizacionReload

  },
  {
    path:"/welcomeAprende",
    name:"welcomeAprende",
    component:welcomeAprende
  },
  {
    //demo
    path: '/reactivos-categorizacion-columnas',
    name: 'reactivosCategorizacionColumnas',
    component: reactivosCategorizacionColumnas
  },
  {
    //demo
    path: '/examen-avanza-nivelando/:uuid_examen',
    name: 'examenAvanzaNivelando',
    component: examenAvanzaNivelando
  },
  {
    path: '/sin-examen',
    name: 'sinExamen',
    component: sinExamen
  },
  {
    path: '/reactivo-verdadero-falso',
    name: 'reactivoVerdaderoFalso',
    component: reactivoVerdaderoFalso
  },
  {
    path: '/b-select',
    name: 'pocBSelect',
    component: pocBSelect
  },
  {
    path: '/reactivo-categorizacion',
    name: 'reactivoCategorizacion',
    component: reactivoCategorizacion
  },
  {
    path: '/reactivos-ordenamiento',
    name: 'ordenamiento',
    component: ordenamiento
  },
  {
    path: '/dashboard-importaciones',
    name: 'dashboardImportaciones',
    component: dashboardImportaciones
  },
  {
    path: '/dashboard-grupos',
    name: 'dashboardGrupos',
    component: dashboardGrupos
  },
  {
    path: '/dashboard-asignaciones',
    name: 'dashboardAsignaciones',
    component: dashboardAsignaciones
  },
  {
    path: '/reactivo-relacionar-columnas',
    name: 'reactivoRelacionarColumnas',
    component: reactivoRelacionarColumnas
  },
/*  {
    path: '/reactivo-verdadero-falso',
    name: 'reactivosVerdaderoFalso',
    component: reactivosVerdaderoFalso
  },*/
  {
    path: '/reactivo-columnas',
    name: 'reactivosColumnas',
    component: reactivosColumnas
  },
  {
    path: '/poc-reactivo-ordenamiento',
    name: 'pocReactivoOrdenamiento',
    component: pocReactivoOrdenamiento
  },
  {
    path: '/check',     //EXAMEN N1
    name: 'coundown',
    component: coundown
  },
  {
    path:"/noAutorizado",
    name:"noAutorizado",
    component:noAutorizado
  },
  {
    path:"/examen/:uuid_examen",
    name:"examen",
    component:examen
  },
  {
    path:"/examen/aprende/:uuid_examen",
    name:"examenNivelando",
    component:examenNivelando
  },


  {
    //reactivos varias respuestas.
    path:"/crear-examen",
    name:"crearExamen",
    component:crearExamen
  },
  {
    //reactivos varias respuestas.
    path:"/dashboard-examenes",
    name:"dashboardExamenes",
    component:dashboardExamenes
  },
  //  REACTIVOS
  {
    //reactivos varias respuestas.
    path:"/dashboard-preguntas",
    name:"dashboardPreguntas",
    component:dashboardPreguntas
  },
  {
    //reactivos varias respuestas.
    path:"/reactivo-varias-respuestas",
    name:"reactivoVariasRespuestas",
    component:reactivoVariasRespuestas
  },
  {
    path:"/reproductor-mp3",
    name:"reproductorMp3",
    component:reproductorMp3
  },
  {
    //reactivos de opcion multiple unica respuesta.
    path:"/reactivo-opcion-multiple-1",
    name:"opcionMultipleUnaRespuesta",
    component:opcionMultipleUnaRespuesta
  },

  {
    //
    path: "",
    name: "Home",
    component: Home,
  }
]


const routes = [
  {
    path: '/',
    name: '',
    component: Layout1,
    children: childRoute()
  },
  {
    path:"/examenv2/:examen/:token",
    name:"examenv2",
    component:examenv2,

  },

    //todo
    //se usará esta vista para test de las funcionalidades de mostrar las respuestas correctas
    //después de responder el examen
    //al momento de mostrar la calificación.
  {
    path:"/examenv2Test/:examen",
    name:"examenv2Test",
    component:examenv2Test,

  },
  {
    path:"/reporte-examen/:user_id_analize",
    name:"reporteExamen",
    component:reporteExamen,

  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
