<template>
  <div class="column">
    <h3>Operaciones con Arreglos</h3>
<!--    <draggable v-model="items" @end="handleDrop">
      <draggable-item
          v-for="(item ) in items"
          :key="item.id"
          :item="item"
      />
    </draggable>-->
  </div>
</template>

<script>
/*import draggable from 'vuedraggable';
import DraggableItem from './DraggableItem.vue';*/

export default {
  components: {
/*    draggable,
    DraggableItem*/
  },
  props: ['title'],
  data() {
    return {
      items: ['90-a','91-b'],
      arrayPreguntasCategorizacion:[],
      claveBuscada: '91-b'
    };
  },
  methods: {
    iniciar(){
      this.items.forEach(elemento=>{
        //agregar un elemento al arreglo
        this.arrayPreguntasCategorizacion.push({
          uuid_pregunta:elemento,
          categorias:[]
        })
      })
      let valor = this.claveBuscada
      let propiedadNueva = "propiedad_nueva";
      let nuevaCategoria = {'uuid_categoria':'300-cat',claves:[]}
      let nuevaCategoria2 = {'uuid_categoria':'301-cat',claves:[]}

      let indice = this.arrayPreguntasCategorizacion.findIndex(function(item) {
        return item.uuid_pregunta === valor;
      });

      this.arrayPreguntasCategorizacion[indice][propiedadNueva] = "val-100"
      let elementoEncontrado = this.arrayPreguntasCategorizacion.find(item => item.uuid_pregunta === this.claveBuscada);
      if (elementoEncontrado) {

        elementoEncontrado.categorias.push(nuevaCategoria);
        elementoEncontrado.categorias.push(nuevaCategoria2);



        //buscar una categoria en especial
        let categoriaEncontrada = elementoEncontrado.categorias.find(item=> item.uuid_categoria === '301-cat')
        categoriaEncontrada.claves.push('700-a')
        categoriaEncontrada.claves.push('701-a')
        categoriaEncontrada.claves.push('702-a')

      } else {
        console.log("Clave no encontrada");
      }
    },
    eliminarClaveCategoria(){
      let uuid_preguntaBuscada = '91-b'
      let claveEliminar ="701-a"

      this.arrayPreguntasCategorizacion.forEach(pregunta=>{
        pregunta.categorias.forEach(categoria=>{
          categoria.claves.forEach((clave,index)=>{
            if(pregunta.uuid_pregunta===uuid_preguntaBuscada && clave===claveEliminar){
              categoria.claves.splice(index,1)
            }
          })
        })
      })
/*      console.log("despues de eliminación")
      console.log(this.arrayPreguntasCategorizacion)*/
/*
ejemplo anterior
[
    {
        "uuid_pregunta": "90-a",
        "categorias": []
    },
    {
        "uuid_pregunta": "91-b",
        "categorias": [
            {
                "uuid_categoria": "300-cat",
                "claves": []
            },
            {
                "uuid_categoria": "301-cat",
                "claves": [
                    "700-a",
                    "701-a",
                    "702-a"
                ]
            }
        ],
        "propiedad_nueva": "val-100"
    }
]
 */

    },
    agregarClaveNuevaPosicion(){
      let elementoEncontrado = this.arrayPreguntasCategorizacion.find(item => item.uuid_pregunta === this.claveBuscada);
      let categoriaEncontrada = elementoEncontrado.categorias.find(item=> item.uuid_categoria === '300-cat')
      categoriaEncontrada.claves.push('701-a')
/*      console.log("despues de cambiar de categoría")
      console.log(this.arrayPreguntasCategorizacion)*/
    }
/*    handleDrop(event) {
      // Aquí emites un evento con los datos necesarios
      this.$emit('itemDropped', {
        columnIndex: this.title,
        itemIndex: event.newIndex
      });
    }*/
  },
  mounted(){
    this.iniciar()
    this.eliminarClaveCategoria()
    this.agregarClaveNuevaPosicion()

  }

};
</script>

<style scoped>
.column {
  border: 1px solid #ddd;
  background-color: #f0f0f0;
  padding: 10px;
  margin: 10px;
  width: 200px;
}
</style>
