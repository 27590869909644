import '@babel/polyfill'
import 'mutationobserver-shim'
import Raphael from 'raphael/raphael'
global.Raphael = Raphael
import Vue from 'vue'
import './plugins'
import App from './App.vue'
import router from './router'
import store from './store'
import './directives'
import 'leaflet/dist/leaflet.css';
import DateFilter from "./filters/date";
import DateIsoFilter from "./filters/isoDate";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueYouTubeEmbed from 'vue-youtube-embed';
// @ is an alias to /src
import axios from "axios";

const API_BASE_URL = process.env.VUE_APP_API_URL;
//Vue.prototype.$variableGlobal = 5000;

Vue.use({
    install(Vue) {
        Vue.prototype.$api = axios.create({
            baseURL: API_BASE_URL,
            withCredentials: true,
/*            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization'
            }*/
        });

        let url = process.env.VUE_APP_API_URL;  //http://localhost:8000
        const routeParams  = window.location.search.substr(1);
        const currentRoute  = window.location.pathname;
        console.warn(currentRoute)
        if (currentRoute === "/examen") {
            //console.log("aqui entro")
            console.warn("aqui entro en /examen")
            Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                //window.location.href = url + "/redirect" +currentRoute+routeParams;
                window.location.href = url + "/redirect?ruta=" + currentRoute+"&"+routeParams;
            });
        }
        else if (currentRoute === "/poc-reactivo-categorizacion") {
            console.warn("aqui entro en /examen")
            Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                window.location.href = url + "/redirect?ruta=" + currentRoute+"&"+routeParams;
            });
        }
        else if (currentRoute === "/inscripcion") {
            //console.log("aqui entro")
            Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                window.location.href = url + "/api" +currentRoute+routeParams;
            });
        }
        else if (currentRoute === "/viewExamen") {
            console.warn('entrando para examen >>>>>>>>');
            Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                window.location.href = url + "/redirect?" + currentRoute+"&"+routeParams;
            });
        }

        Vue.prototype.$api.interceptors.response.use(
            function (response) {
                if (currentRoute === "/uuid_examen") {
                    //console.warn('entra a response show-conv-login front');
                    Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                    });
                }
                else if (currentRoute === "/viewExamen") {
                    console.warn('entrando para examen');
                    Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                    });
                }

                return response;
            },
            function (error) {
                if (error.response.status === 401) {
                    const currentRoute  = window.location.pathname;
                    let url = process.env.VUE_APP_API_URL;
                    console.warn('entra a 401 show-conv-login front xxxx');
                    console.warn("currentRoute")
                    console.warn(currentRoute)
                    if (currentRoute === "/view") {
                        Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                            window.location.href = url + "/api" + window.location.href;
                        });
                    }
/*
                    else if (currentRoute === "/examen") {
                        console.warn("aqui entro en /examenNivelando")
                        Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                            //window.location.href = url + "/redirect" +currentRoute+routeParams;
                            window.location.href = url + "/redirect?ruta=" + currentRoute+"&"+routeParams;
                        });
                    }*/

                    else {
                        Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                            window.location.href =  url + "/redirect";
                        });
                    }
                }
                if (error.response.status === 419) {
                    //console.warn('entra a 419 show-conv-login front');
                    console.log("error detectado 419---->")
                    Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                    });
                }
                if (error.response.status === 403) {
                    console.warn('entra a 403 show-conv-login front');
                }
                return Promise.reject(error);
            }
        );
    },
});

Vue.use(VueYouTubeEmbed);
Vue.config.productionTip = false;
Vue.component('v-select', vSelect)
Vue.filter("date", DateFilter);
Vue.filter("isoDate", DateIsoFilter);
new Vue({
  router,
  store,
  vSelect,
  render: h => h(App)
}).$mount('#app')
