<template>
  <b-container fluid>
    <b-modal id="modalEliminarPregunta" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalEliminarPregunta"
             title="¡Atención!"
             v-model="modalFaltanPreguntas"
    >
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¡Favor de verificar, faltan preguntas por responder.!
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="modalFaltanPreguntas=false"
                  title="Enterado"
              >
                Aceptar
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>



    <b-modal no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg"
             hide-footer persistent
             ref="modalEliminarPregunta"
             title="Confirmar finalizar examen"
             v-model="modalEliminarPregunta"
    >
      <b-alert v-model="modalConfirmacionEliminarPregunta" variant="light" show>
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          {{ mensajeConfirmacion }}
        </b-button>
      </b-alert>
      <!--      <Notificacion ref="notificacionEliminarPregunta"/>-->
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="danger" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
          ¿Desea dar por finalizado su examen?
        </b-alert>
        <b-card-text>
          <b-row class="justify-content-end">
            <div class="d-flex align-items-right">
              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="finalizarExamenByUser()"
                  title="Finalizar examen"
              >
                Sí
              </b-button>
<!--              <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="finalizarExamenByUser()"
                  title="Finalizar examen"
                  :disabled="disabledBtnsEliminarPregunta"
              >
                Sí
              </b-button>-->
              <b-button
                  variant="primary"
                  size="sm"
                  class="float-right mx-2 my-1"
                  title="Cancelar"
                  @click="modalEliminarPregunta=false"
                  :disabled="disabledBtnsEliminarPregunta"
              >
                No
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
    </b-modal>


    <b-alert v-model="modalConfirmacionImagen" variant="light" show>
      <b-button variant="primary" disabled>
        <b-spinner small type="grow"></b-spinner>
        {{ mensajeConfirmacion }}
      </b-button>
    </b-alert>
    <Notificacion ref="notificacion"/>
    <!--    <div id="examenFinalizaTiempo" v-if="this.$store.getters.usuario.uuid_examen_permitido!=uuid_examen" style="margin-top: 50px;">
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <b-card>
                  <h5>¡No tienes permisos para este examen.!
                  </h5>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </div>-->
    <b-row v-if="mostrarRecomendacion===true"
           style="font-family: 'Roboto', sans-serif; font-size: 16px; line-height: 1.5;margin-top: 20px">
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <b-card>
              <div id="indicaciones"
                   style="font-family: 'Roboto', sans-serif; font-size: 16px; line-height: 1.5;margin-top: 20px">
                <p style="text-align: justify;">Antes de pulsar el botón de inicio considera lo siguiente:</p>
                <ul style="text-align: justify;">
                  <!--                  <li>
                                      <b>Cuida tus tiempos.</b>
                                      Administrar el tiempo durante el examen marca la diferencia entre obtener el resultado positivo y
                                      uno negativo. El examen está diseñado para completarse en un máximo de 3 horas (180 minutos).
                                    </li>-->
                  <li>
                    <b>Evita dejar respuestas en blanco.</b>
                    Puedes saltar las preguntas que no puedas contestar de forma inmediata y regresar a ellas al final,
                    pero asegúrate de no dejarlas en blanco antes de enviar tu evaluación, pues serán consideradas como
                    incorrectas.
                  </li>
                  <li>
                    <b>Controla los nervios.</b>
                    Para ello busca tomar el simulacro en un día que tengas el tiempo suficiente, estés descansado y te
                    ubiques en un espacio cómodo y libre de distracciones.
                  </li>
                  <li>
                    <b>Leer con atención el enunciado es importante.</b>
                    Es importante que leas con calma y de forma analítica cada escenario o pregunta para que selecciones
                    la respuesta correcta.
                  </li>
                  <li>
                    <b>Revisa el examen antes de entregarlo.</b>
                    Este detalle puede marcar la diferencia entre obtener una buena calificación de una excelente o un
                    resultado a mejorar.
                  </li>
                </ul>
              </div>
              <b-row v-if="mostrarRecomendacion===true" class="text-center">
                <b-col cols="12" class="text-center">
                  <b-button
                      variant="success"
                      size="sm"
                      class="float-right mx-2 my-1"
                      @click="iniciarExamen()"
                      title="Iniciar examen"

                  >
                    Iniciar examen
                  </b-button>
                  <!--TODO DESCOMENTAR DESPUES DE FINALIZAR TEST -->

<!--                  <b-button
                      variant="success"
                      size="sm"
                      class="float-right mx-2 my-1"
                      @click="iniciarExamen()"
                      title="Iniciar examen"
                      :disabled="disabledBtnIniciarExamen"
                  >
                    Iniciar examen
                  </b-button>-->
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div id="examenTerminadoUser" v-if="examenFinalizadoUser===true || estatusExamen===3" style="margin-top: 50px;">
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <b-card>
              <h5>¡Gracias!, has finalizado tu examen.
              </h5>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" style="text-align: center">
            <h2><span style="color: #952f81">Tu Calificacion: {{calificacionFinal}}</span></h2>
          </b-col>
        </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-card>
                      <h5><span style="color: #952f81">A continuación se enlistan las respuestas que tuviste correctas:</span></h5>
                    </b-card>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                      <div id="respuestasFinales" v-for="(question, index) in concentradoCalificaciones" :key="index" class="mb-4">
<!--                        <b-row v-if="question.totalAciertos>0">-->
                        <b-row >
                          <b-col cols="12">

                          <b-card>
                          <b-row>
                            <h3>
                          <div v-html="question.pregunta"
                               style="font-family: sans-serif;font-size: 16px;line-height: 1.5;margin-bottom: 10px"></div></h3>
                          </b-row>
                              <div id="respuestaOpciones" v-for="(respuestaConcentrado, index) in question.respuestas" :key="index" class="mb-4">
                                <b-row>

                                    <div v-html="respuestaConcentrado.respuesta"
                                         style="font-family: 'Roboto', sans-serif;font-size: 16px;line-height: 1.5;"></div>
                                  <div id="calificacionRespuesta">
                                    <div id="respuestaOk" v-if="respuestaConcentrado.seleccionada===1">

                                      <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                                title="Respuesta correcta" class="ml-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                        </svg>
                                      </b-button>
                                    </div>
                                  </div>
                                </b-row>
                              </div>
                        </b-card>

                          </b-col>
                        </b-row>
                      </div>
                  </b-col>
                </b-row>
      </b-col>
    </div>


    <div id="examenFinalizaTiempo"
         v-if="mensajeFinalizadoPorTiempo===true"
         style="margin-top: 50px;">
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <b-card>
              <h5>Su examen ha concluido, por haber alcanzo el límite de tiempo.
              </h5>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </div>


    <div id="appAvanza" v-if="examenActivo===true"
         style="margin-top: 50px;">
      <h5>Lee con atención y contesta correctamente.
      </h5>
      <br>
      <div id="examen" v-for="(question, index) in preguntas.data" :key="index" class="mb-4">
        <b-card v-if="question.preguntas.tiporeactivo_id===1">

          <div v-html="index+1 + '.-'+question.preguntas.descripcion"
               style="font-family: 'Roboto', sans-serif;font-size: 16px;line-height: 1.5;">

          </div>
          <b-form-radio-group
              :options="question.preguntas.opciones_respuesta"
              html-field="respuesta"
              value-field="uuid_respuesta"
              v-model="respuestaTipo1[`${question.preguntas.uuid_pregunta}`]"
              @change="respuestaSeleccionada"
              stacked
              :disabled="disabledRespuesta"
          >
            <!--            {{question.preguntas.opciones_respuesta.uuid_respuesta}}-->
          </b-form-radio-group>
        </b-card>
        <b-card v-if="question.preguntas.tiporeactivo_id===2">
          <div v-html="index+1 + '.-'+question.preguntas.descripcion"
               style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
          <b-form-checkbox-group
              :options="question.preguntas.opciones_respuesta"
              html-field="respuesta"
              value-field="uuid_respuesta"
              v-model="respuestaTipo2[`${question.preguntas.uuid_pregunta}`]"
              @change="respuestaTipo2Seleccionada"
              class="mb-2"
              stacked
              :disabled="disabledRespuesta"
          ></b-form-checkbox-group>
        </b-card>
        <b-card v-if="question.preguntas.tiporeactivo_id===3">
          <div v-html="index+1 + '.-'+question.preguntas.descripcion"
               style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
          <b-table class="table data-table"
                   :items="question.preguntas.reactivos_columna_a"
                   :fields="reactivos_fields"
                   :current-page="currentPage_reactivos"
                   :per-page="perPage_reactivos"
                   :filter="filter_reactivos"
                   :filter-included-fields="filterOn_reactivos"
                   :striped="true"
                   hover
                   show-empty
                   :small="true"
          >
            <template #cell(descripcion)="row">
              <div v-html="row.value"></div>
            </template>
            <template #cell(concepto)="row">
              <b-form-select
                  v-model="respuestaTipo3[`${row.item.uuid_columnaA}`]"
                  :options="question.preguntas.reactivos_columna_b"
                  text-field="descripcion"
                  value-field="uuid_columnaB"
                  class="mb-3"
                  size="sm"
                  @change="respuestaTipo3Seleccionada(row)"
                  :disabled="disabledRespuesta"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>-- Elige una opción --</b-form-select-option>
                </template>
              </b-form-select>

            </template>
          </b-table>
        </b-card>

        <b-card v-if="question.preguntas.tiporeactivo_id===4">
          <div v-html="index+1 + '.-'+question.preguntas.descripcion"
               style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
          <b-row class="justify-content-end">
            <b-col sm="12">
              <draggable
                  :id="`drag${question.preguntas.id}`"
                  v-model="question.preguntas.conceptos_ordenamiento"
                  class="list-group"
                  ghost-class="ghost"
                  @update="updateConceptosOrdenamiento(question.preguntas.id)"
                  :disabled="disabledRespuesta"
              >

                <div
                    class="list-group-item"
                    v-for="element in question.preguntas.conceptos_ordenamiento"
                    :key="element.uuid_concepto"
                >
                  {{ element.concepto }}
                </div>
              </draggable>
            </b-col>
          </b-row>
        </b-card>

        <b-card v-if="question.preguntas.tiporeactivo_id===5">
          <div v-html="index+1 + '.-'+question.preguntas.descripcion"
               style="font-family: 'Roboto', sans-serif;line-height: 1.5;"></div>
          <b-row class="justify-content-start">
            <div id="categoria" v-for="(data, indexData) in categorias" :key="indexData">
              <b-col sm="12" v-if="data.idPregunta===question.preguntas.id">
                <div id="columnasDraggables">
                  <div class="columns">
                    <b-row>
                      <div v-for="(column, columnIndex) in data.items" :key="columnIndex" class="column">
                        <b-col cols="3" md="auto">
                          <h6>{{ column.name }}</h6>
                          <draggable
                              class="list-group"
                              :list="column.items"
                              group="animals"
                              itemKey="uuid_opcion"
                              @add="handleDragChange(data.idPregunta, column,columnIndex, $event)"
                              :disabled="disabledRespuesta"
                          >
                            <div
                                class="list-group-item"
                                v-for="(respuesta) in column.items"
                                :key="respuesta.uuid_opcion"

                            >
                              {{ respuesta.opcion }}
                            </div>
                          </draggable>
                        </b-col>
                      </div>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </div>
          </b-row>
        </b-card>

        <b-card v-if="question.preguntas.tiporeactivo_id===6">
          <div v-html="index+1 + '.-'+question.preguntas.descripcion"
               style="font-family: 'Roboto', sans-serif;font-size: 16px;line-height: 1.5;"></div>
          <b-form-radio-group
              :options="question.preguntas.opciones_respuesta"
              html-field="respuesta"
              value-field="uuid_respuesta"
              v-model="respuestaTipo6[`${question.preguntas.uuid_pregunta}`]"
              @change="respuestaSeleccionada6"
              stacked
              :disabled="disabledRespuesta"
          >
          </b-form-radio-group>
        </b-card>


      </div>
      <button
          @click="submitExam"
          class="btn btn-primary"
          v-if="examenActivo==true"
      >
        Finalizar Examen
      </button>
    </div>
    <!--    <span class="float" v-if="examenActivo===true">
           <b-spinner small type="grow"></b-spinner><b>Minutos restantes:</b> <p style="font-weight: bold;">{{
            counter
          }}</p>
        </span>-->
  </b-container>
</template>

<script>
import ExamenService from '@/services/ExamenService';
import Notificacion from '@/components/Notificacion.vue'
import draggable from "vuedraggable";
//import _ from 'lodash';

export default {
  components: {

    Notificacion,
    draggable
  },
  data() {
    return {
      disabledRespuesta:false,
      clave_uuid_examen:null,
      disabledBtnIniciarExamen:true,
      calificacionFinal:null,
      concentradoCalificaciones:null,
      modalFaltanPreguntas:false,
      respuestasOrdenamiento:[],
      activeNames:[],
      respuestaTipo5: [],
      respuestasColumna : [],
      concentradoRespuestasPorColumna : [],
      respuestasCategorizacion: {}, // Nuevo objeto para almacenar las respuestas arrastradas
      //para reactivo de categorizacion
      categoriasArray: [],
      opcionesArray: [], // Aquí almacenaremos los elementos de opciones
      categorias: [],    // Aquí almacenaremos las categorías y sus opciones
      //respuestas: [],
      nombres_categorias: [],
      //fin reactivo de categorizacion


      examenFinalizadoUser: false,
      modalEliminarPregunta: false,
      modalConfirmacionEliminarPregunta: false,
      disabledBtnsEliminarPregunta: false,
      respuestaTipo1: [],
      respuestaTipo2: [],
      respuestaTipo3: [],
      respuestaTipo6: [],
      mensajeFinalizadoPorTiempo: false,
      selected: null,
      reactivos_fields: [
        {label: 'Descripcion', key: 'descripcion', class: 'text-left'},
        {label: 'Concepto', key: 'concepto', class: 'text-left'},
      ],
      currentPage_reactivos: 1,
      perPage_reactivos: 20,
      filter_reactivos: null,
      filterOn_reactivos: [],
      modalConfirmacionImagen: false,
      mensajeConfirmacion: null,
      examenActivo: false,
      uuid_examen: (this.$route.params.examen) ? (this.$route.params.examen) : 0,
      token: (this.$route.params.token) ? (this.$route.params.token) : null,
      preguntas: [],
      respuestas: [],
      selectedOptions: [], // Respuestas seleccionadas por el usuario
      checked: [], // Respuestas seleccionadas por el usuario
      btnFinalizarexamen: false,
      totalTime: 10800, // Tiempo total en segundos
      timeRemaining: 10800, // Tiempo restante en segundos
      intervalId: null, // ID del intervalo para controlar el contador
      isRunning: false, // Estado de ejecución del contador

      //variables del examen
      examen_finalizado: 0,
      mostrar_resultados: 0,

      estatusExamen: null,
      mostrarRecomendacion: true,
      counter: 0,
      interval: null,
      tiempoExamen: null,
    }
  },
  mounted() {
    //this.validateToken()
  },

  computed: {
    formatTime() {
      const minutes = Math.floor(this.timeRemaining / 60);
      const seconds = this.timeRemaining % 60;
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
  },
  methods: {
    async verificarCalificacionStudent(){
      let formData = new FormData();
      this.disabledBtnsEliminarPregunta = true
      formData.append("uuid_examen_fk", this.clave_uuid_examen);
      const response = await ExamenService.verificarCalificacionUser(formData);
      let {
        success,
        estatusExamen,
        calificacionFinal,
        concentradoCalificaciones

      } = response.data;
      if (success) {
        this.modalEliminarPregunta = false
        if (estatusExamen == 3) {
          this.mostrarRecomendacion = false
          this.mensajeFinalizadoPorTiempo = false
          this.examenActivo = false
          this.examenFinalizadoUser = true
          this.calificacionFinal = calificacionFinal.toFixed(2)
          this.concentradoCalificaciones = concentradoCalificaciones
          //desplegar mensaje lo sentimos
        }
        else{
          /*          console.log("response data")
                    console.log(response.data)*/
          this.modalFaltanPreguntas = true
        }
      }
    },
    async validateToken(){
      await ExamenService.generarCookie();
      let formData = new FormData();
      formData.append("token", this.token);
      formData.append("uuid_examen", this.uuid_examen);
      const response = await ExamenService.validarToken(formData);
      let {
        success
      } = response.data;
      if (!success) {
        this.showNotificacion("¡Token no válido!", "danger")
      }
      else {
        this.disabledBtnIniciarExamen = false
      }
    },
    async saveRespuestasOrdenamiento(uuid_pregunta,respuestas_ordenamiento) {
      let formData = new FormData();
      formData.append("uuid_pregunta", uuid_pregunta);
      formData.append("respuestas_ordenamiento", JSON.stringify(respuestas_ordenamiento));
      formData.append("uuid_examen", this.clave_uuid_examen);
      this.disabledRespuesta = true
      const response = await ExamenService.storeRespuestaOrdenamiento(formData);

      let {
        success
      } = response.data;
      if (success) {
        this.disabledRespuesta = false
        this.showNotificacion("¡Respuesta guardada!", "success")
      } else
        this.showNotificacion("¡Respuesta no guardada. Intentarlo más tarde!", "danger")
    },
    updateConceptosOrdenamiento(idPregunta){
      this.preguntas.data.forEach((item)=>{
        /*        console.log("item")
                console.log(item)*/
        item.preguntas.conceptos_ordenamiento.forEach((item2)=>{
          console.log("item2")
          console.log(item2)
        });
      });

      let div = "drag"+idPregunta
      let uuid_pregunta
      const dragDiv = document.getElementById(div);
      // Verifica si el div existe antes de continuar
      if (dragDiv) {
        this.preguntas.data.forEach((item)=>{
          if(item.preguntas.id === parseInt(idPregunta)){
            uuid_pregunta = item.uuid_pregunta_fk
          }
        });
        // Obtén todos los elementos hijos del div
        const listItems = dragDiv.getElementsByClassName("list-group-item");
        // Recorre los elementos y muestra su contenido
        this.respuestasOrdenamiento = []
        for (let i = 0; i < listItems.length; i++) {
          const listItem = listItems[i];
          const content = listItem.textContent.trim();
          this.preguntas.data.forEach((item)=>{
            item.preguntas.conceptos_ordenamiento.forEach((item2)=>{
              if(item2.concepto === content.trim()){
                this.respuestasOrdenamiento.push({
                  uuid_concepto: item2.uuid_concepto
                });
              }
            });
          });
          /*          console.log(`Contenido del elemento ${i + 1}: ${content}`);
                       this.respuestasOrdenamiento.push({
                        id: content
                      });*/
        }
      }
      /*      console.log("Array uuid_concepto")
            console.log(this.respuestasOrdenamiento)*/
      this.saveRespuestasOrdenamiento(uuid_pregunta,this.respuestasOrdenamiento)
    },

    log: function(evt) {
      window.console.log(evt);
    },

    handleDragChange(idPregunta, columnaNuevaUbicacion,columnIndex,event) {
      let textoOpcionSelected = event.item.innerText
      let uuid_pregunta
      let uuid_opcion
      let uuid_categoria
      uuid_categoria = columnaNuevaUbicacion.uuid_categoria
      this.preguntas.data.forEach((item)=>{
        if(item.preguntas.id === parseInt(idPregunta)){
          uuid_pregunta = item.uuid_pregunta_fk
        }
      });
      this.categorias.forEach((item)=>{
        item.items.forEach((item2)=>{
          item2.items.forEach((item3)=>{
            if(textoOpcionSelected.trim() === item3.opcion) {
              uuid_opcion = item3.uuid_opcion
            }
          })
        });
      });
      this.saveRespuestasCategorizacion(uuid_pregunta,uuid_categoria,uuid_opcion)
    },


    async finalizarExamenByUser() {
      let formData = new FormData();
      //formData.append("tiempo_actual", this.counter);
      this.disabledBtnsEliminarPregunta = true
      formData.append("uuid_examen_fk", this.clave_uuid_examen);
      const response = await ExamenService.finalizarExamenByUser(formData);
      let {
        success,
        estatusExamen,
        calificacionFinal,
        concentradoCalificaciones

      } = response.data;
      if (success) {
        console.log("respuestas examen")
        console.log(response.data)
        console.log(concentradoCalificaciones)
        //this.showNotificacion("¡Respuesta guardada!", "success")
        this.modalEliminarPregunta = false
        if (estatusExamen == 3) {
          this.mostrarRecomendacion = false
          this.mensajeFinalizadoPorTiempo = false
          this.examenActivo = false
          this.examenFinalizadoUser = true
          this.calificacionFinal = calificacionFinal.toFixed(2)
          this.concentradoCalificaciones = concentradoCalificaciones
          //desplegar mensaje lo sentimos
        }
        else{
          console.log("response data")
          console.log(response.data)
          this.modalFaltanPreguntas = true
        }
      }

    },
    startCountdown() {
      this.interval = setInterval(() => {
        this.counter--;
        if (this.counter <= 0) {
          clearInterval(this.interval);
          this.mostrarRecomendacion = false
          this.examenActivo = false
          this.mensajeFinalizadoPorTiempo = true
          this.actualizarContador()
        }
      }, 60000);
    },
    async verificarTiempoExamen() {
      const response = await ExamenService.getverificarTiempoExamen(this.clave_uuid_examen);
      this.counter = (response.contador) - 1;
      this.estatusExamen = response.estatusExamen;
    },


    iniciarExamen: async function () {
      this.mensajeConfirmacion = "Espera un momento, estamos preparando tu examen..."
      this.modalConfirmacionImagen = true

      this.mostrarRecomendacion = false
      this.preguntas = []
      //el parametro que se envia del examen, es su id
      const response = await ExamenService.getAllPreguntasRespuestasV2(this.uuid_examen);
      this.preguntas = response.data;
      console.log("PREGUNTAS EXAMEN")
      console.log(this.preguntas)
      this.clave_uuid_examen = this.preguntas.uuid_examen

      this.preguntas.data.forEach((pregunta) => {
        if (pregunta.preguntas.tiporeactivo_id === 1) {
          pregunta.preguntas.opciones_respuesta.forEach((item2) => {

            item2.opciones_respuestas_user.forEach((item3) => {
              if(item2.uuid_respuesta==item3.uuid_respuesta_fk){

                const respuestaNueva = {
                  ...this.respuestaTipo1,
                  [pregunta.preguntas.uuid_pregunta]: item3.uuid_respuesta_fk
                };
                this.respuestaTipo1 = respuestaNueva;
              }
            });
          });
        }

        else if (pregunta.preguntas.tiporeactivo_id === 2) {
          pregunta.preguntas.opciones_respuesta.forEach((item2) => {
            item2.opciones_respuestas_user.forEach((item3) => {
              if(item2.uuid_respuesta==item3.uuid_respuesta_fk){
                let respuestaValores = Array.from(this.respuestaTipo2[pregunta.preguntas.uuid_pregunta] ?? []);
                respuestaValores.push(item3.uuid_respuesta_fk);
                const respuestaNueva = {
                  ...this.respuestaTipo2,
                  [pregunta.preguntas.uuid_pregunta]: respuestaValores
                };
                this.respuestaTipo2 = respuestaNueva;
              }
            });
          });
        }

        else if (pregunta.preguntas.tiporeactivo_id === 3) {
          pregunta.preguntas.reactivos_columna_a.forEach(columnaA => {
            columnaA.respuestas_user_columna_a.forEach((item3)=>{
              const respuestaNueva = {
                ...this.respuestaTipo3,
                [columnaA.uuid_columnaA]: item3.uuid_columnaB_fk
              };
              this.respuestaTipo3 = respuestaNueva;
            });
          });
        }

        else if (pregunta.preguntas.tiporeactivo_id === 6) {
          pregunta.preguntas.opciones_respuesta.forEach((item2) => {
            /*            console.log("item2")
                        console.log(item2)*/
            item2.opciones_respuestas_user.forEach((item3) => {
              if(item2.uuid_respuesta==item3.uuid_respuesta_fk){
                /*                console.log("pregunta encontrada")
                                console.log("pregunta.preguntas.uuid_pregunta")
                                console.log(pregunta.preguntas.uuid_pregunta)*/
                const respuestaNueva = {
                  ...this.respuestaTipo6,
                  [pregunta.preguntas.uuid_pregunta]: item3.uuid_respuesta_fk
                };
                this.respuestaTipo6 = respuestaNueva;
              }
            });
          });
        }




        if (pregunta.preguntas.tiporeactivo_id === 5) {
          let idPregunta = pregunta.preguntas.id
          this.nombres_categorias = []
          this.categoriasArray = []
          this.opcionesArray = []

          pregunta.preguntas.categorias.forEach((item2) => {
            this.nombres_categorias.push({
              id: idPregunta,
              name: item2.categoria,
              uuid_categoria: item2.uuid_categoria,
              items: []
            });

            item2.opciones.forEach((item3) => {
              this.opcionesArray.push(item3);
            });
          });

          this.categoriasArray.push({
            id: idPregunta,
            name: "Conceptos a ubicar",
            uuid_categoria: 0,
            items: this.opcionesArray
          });
          this.nombres_categorias.forEach((obj) => {
            this.categoriasArray.push({
              name: obj.name,
              uuid_categoria: obj.uuid_categoria,
              items: obj.items
            });
          });
          this.categorias.push({
            idPregunta: pregunta.preguntas.id,
            uuid_pregunta:pregunta.preguntas.uuid_pregunta,
            items: this.categoriasArray
          });

        }
        //Recuperar respeustas seleccionadas por el usuario.
        /*            if (pregunta.preguntas.tiporeactivo_id === 1 || pregunta.preguntas.tiporeactivo_id === 2) {
                      pregunta.respuestas.forEach((respuesta) => {
                        if (pregunta.tipo_pregunta === 1 && respuesta.checked) {
                          const respuestaNueva = {
                            ...this.respuestaTipo1,
                            [pregunta.uuid_pregunta]: respuesta.uuid_respuesta
                          };
                          this.respuestaTipo1 = respuestaNueva;
                        }

                        if (pregunta.tipo_pregunta === 2 && respuesta.checked) {
                          let respuestaValores = Array.from(this.respuestaTipo2[pregunta.uuid_pregunta] ?? []);
                          respuestaValores.push(respuesta.uuid_respuesta);
                          const respuestaNueva = {
                            ...this.respuestaTipo2,
                            [pregunta.uuid_pregunta]: respuestaValores
                          };
                          this.respuestaTipo2 = respuestaNueva;
                        }
                      });
                    }*/

        /*
                    if (pregunta.tipo_pregunta === 3) {
                      pregunta.columnaA.forEach(columnaA => {
                        const respuestaNueva = {
                          ...this.respuestaTipo3,
                          [columnaA.uuid_columnaA]: columnaA.respuestaSelected
                        };
                        this.respuestaTipo3 = respuestaNueva;
                      });
                    }*/
      });

      this.mensajeConfirmacion = "Gracias por la espera, ahora puedes iniciar..."
      this.modalConfirmacionImagen = false

//  todo
      let formData = new FormData();
      formData.append("uuid_examen", this.clave_uuid_examen);
      const responseTiempo = await ExamenService.setIniciarExamenAlumno(formData);
      this.counter = (responseTiempo.data.minutos_restantes) - 1;
      this.estatusExamen = responseTiempo.data.estatusExamen;
      //console.log(this.estatusExamen)
      if (this.estatusExamen === 4) { //finalizado por tiempo
        this.examenActivo = false
        this.mensajeFinalizadoPorTiempo = true
      } else if (this.estatusExamen === 3) {
        //this.examenActivo = false
        //this.examenFinalizadoUser = true;
        //aqui mandar a traer al examen calificado
        await this.verificarCalificacionStudent()




      } else if (this.estatusExamen === 1 || this.estatusExamen === 2) {
        this.examenActivo = true
        this.startCountdown();
      }
    },


    async actualizarContador() {
      let formData = new FormData();
      //formData.append("tiempo_actual", this.counter);
      formData.append("uuid_examen_fk", this.clave_uuid_examen);
      const response = await ExamenService.storeTiempoActualizado(formData);
      let {
        success,
        estatusExamen

      } = response.data;
      if (success) {
        //this.showNotificacion("¡Respuesta guardada!", "success")
        if (estatusExamen == 4) {
          //desplegar mensaje lo sentimos
        }
      }

    },
    mostrar() {
      alert(this.counter)
    },
    async saveRowSelected($uuid_columnaB, $uuid_columnaA, $uuid_reactivo_fk, $uuid_examen) {
      let formData = new FormData();
      formData.append("tiempo_actual", this.counter);
      formData.append("uuid_columnaB", $uuid_columnaB);
      formData.append("uuid_columnaA", $uuid_columnaA);
      formData.append("uuid_pregunta", $uuid_reactivo_fk);
      formData.append("uuid_examen", $uuid_examen);
      const response = await ExamenService.storeRespuestaColumna(formData);
      let {
        success
      } = response.data;
      if (success) {
        this.showNotificacion("¡Respuesta guardada!", "success")
      } else
        this.showNotificacion("¡Respuesta no guardada. Favor de revisar tu conexión a internet!", "danger")
    },

    async saveRespuestasRadioSelected(val_uuid_pregunta, uuid_respuesta, tipo_reactivo) {
      let formData = new FormData();
      formData.append("tiempo_actual", this.counter);
      formData.append("uuid_pregunta", val_uuid_pregunta);
      formData.append("uuid_respuesta", uuid_respuesta);
      formData.append("tipo_reactivo", tipo_reactivo);
      formData.append("uuid_examen", this.clave_uuid_examen);
      this.disabledRespuesta = true
      const response = await ExamenService.storeRespuestaRadio(formData);
      let {
        success
      } = response.data;
      if (success) {
        this.disabledRespuesta = false
        this.showNotificacion("¡Respuesta guardada!", "success")
      } else
        this.showNotificacion("¡Respuesta no guardada. Favor de revisar tu conexión a internet!", "danger")
    },


    async saveRespuestasCheckSelected(uuid_pregunta, uuid_respuesta, tipo_reactivo, option) {
      let checked = null
      const indiceRespuesta = this.respuestas.findIndex(respuesta => respuesta.uuid_respuesta === uuid_respuesta);
      // Si no existe, agregar el nuevo elemento
      if (indiceRespuesta === -1) {
        this.respuestas.push({uuid_respuesta: option.uuid_respuesta, isChecked: !option.isChecked});
        checked = !option.isChecked
      } else {
        this.respuestas[indiceRespuesta].isChecked = !this.respuestas[indiceRespuesta].isChecked;
        checked = this.respuestas[indiceRespuesta].isChecked
      }
      let formData = new FormData();
      formData.append("uuid_pregunta", uuid_pregunta);
      //formData.append("tiempo_actual", this.counter);
      formData.append("uuid_respuesta", uuid_respuesta);
      formData.append("tipo_reactivo", tipo_reactivo);
      formData.append("uuid_examen", this.clave_uuid_examen);
      formData.append("checked", checked);

      const response = await ExamenService.storeRespuestaCheckbox(formData);
      let {
        success
      } = response.data;
      if (success) {
        this.showNotificacion("¡Respuesta guardada!", "success")
      } else
        this.showNotificacion("¡Respuesta no guardada. Favor de revisar tu conexión a internet!", "danger")

    },



    async saveRespuestasCategorizacion(uuid_pregunta,uuid_categoria,uuid_opcion) {
      let formData = new FormData();
      formData.append("uuid_pregunta", uuid_pregunta);
      formData.append("uuid_categoria", uuid_categoria);
      formData.append("uuid_opcion", uuid_opcion);
      formData.append("uuid_examen", this.clave_uuid_examen);
      this.disabledRespuesta = true
      const response = await ExamenService.storeRespuestaCategorizacion(formData);

      let {
        success
      } = response.data;
      if (success) {
        this.disabledRespuesta = false
        this.showNotificacion("¡Respuesta guardada!", "success")
      } else
        this.showNotificacion("¡Respuesta no guardada. Intentarlo más tarde!", "danger")
    },

    async getPreguntas(uuid_examen) {
      //this.mensajeConfirmacion = "Favor de esperar un momento, estamos preparando tu examen..."
      //this.modalConfirmacionImagen = true
      this.preguntas = []
      const response = await ExamenService.getAllPreguntasRespuestas(uuid_examen);
      this.preguntas = response.data;

      this.preguntas.forEach(pregunta => {
        pregunta.respuestas.forEach(respuesta => {
          this.respuestas.push({
            uuid_respuesta: respuesta.uuid_respuesta,
            isChecked: respuesta.isChecked
          });
        });
      });
      /*          console.log("preguntas")
                console.log(this.preguntas)*/
      this.mensajeConfirmacion = "Gracias por la espera, ahora puedes iniciar..."
      this.modalConfirmacionImagen = false
      this.examenActivo = true
    },
    submitExam() {

      // Procesar las respuestas seleccionadas por el usuario
      /*          this.mensajeConfirmacion = "Gracias tu examen ha sido enviado, pronto recibirás noticias de tu evaluación..."
                this.modalConfirmacionImagen = true*/
      this.modalEliminarPregunta = true
      // Puedes realizar más acciones aquí, como calcular la puntuación, mostrar resultados, etc.
    },
    respuestaSeleccionada(respuestaUUID) {
      let preguntaUUID = Object.keys(this.respuestaTipo1).find(key => this.respuestaTipo1[key] === respuestaUUID);
      this.saveRespuestasRadioSelected(preguntaUUID, respuestaUUID, 1)
    },
    respuestaSeleccionada6(respuestaUUID) {
      let preguntaUUID = Object.keys(this.respuestaTipo6).find(key => this.respuestaTipo6[key] === respuestaUUID);
      this.saveRespuestasRadioSelected(preguntaUUID, respuestaUUID, 6)
    },
    async respuestaTipo2Seleccionada(respuestasUUID) {
      /*          let contieneComa = 0
                let esArreglo = 0*/
      // let arregloRespuestas = null
      //let longitudCadena=0
      let preguntaUUID = Object.keys(this.respuestaTipo2).find(key => JSON.stringify(this.respuestaTipo2[key]) === JSON.stringify(respuestasUUID));

      //console.log(preguntaUUID, respuestasUUID);

      let formData = new FormData();
      formData.append("tiempo_actual", this.counter);
      formData.append("uuid_pregunta", preguntaUUID);
      formData.append("arrayUuid_respuesas", respuestasUUID);

      formData.append("tipo_reactivo", 2);
      formData.append("uuid_examen", this.clave_uuid_examen);
      this.disabledRespuesta = true
      const response = await ExamenService.storeRespuestaCheckbox(formData);

      let {
        success
      } = response.data;
      if (success) {
        this.disabledRespuesta = false
        this.showNotificacion("¡Respuesta guardada!", "success")
      } else
        this.showNotificacion("¡Respuesta no guardada. Favor de revisar tu conexión a internet!", "danger")


    },
    async respuestaTipo3Seleccionada(rowSeleccionado) {
      let uuidColumnaBSeleccionada = this.respuestaTipo3[rowSeleccionado.item.uuid_columnaA];
      //todo mandar a guardar
      let formData = new FormData();
      formData.append("tiempo_actual", this.counter);
      formData.append("uuid_columnaB", uuidColumnaBSeleccionada);
      formData.append("uuid_columnaA", rowSeleccionado.item.uuid_columnaA);
      formData.append("uuid_pregunta", rowSeleccionado.item.uuid_reactivo_fk);
      formData.append("uuid_examen", this.clave_uuid_examen);
      this.disabledRespuesta = true
      const response = await ExamenService.storeRespuestaColumna(formData);

      let {
        success
      } = response.data;
      if (success) {
        this.disabledRespuesta = false
        this.showNotificacion("¡Respuesta guardada!", "success")
      } else
        this.showNotificacion("¡Respuesta no guardada. Favor de revisar tu conexión a internet!", "danger")
    },
    showNotificacion(message, tipo) {
      this.$refs.notificacion.mostrar(message, tipo, 2000)
    },
    startTimer() {
      this.isRunning = true;
      this.intervalId = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
        } else {
          this.stopTimer();
          this.performAction();
        }
      }, 1000);
    },
    pauseTimer() {
      clearInterval(this.intervalId);
      this.isRunning = false;
    },
    continueTimer() {
      this.isRunning = true;
      this.intervalId = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
        } else {
          this.stopTimer();
          this.performAction();
        }
      }, 1000);
    },
    resetTimer() {
      clearInterval(this.intervalId);
      this.timeRemaining = this.totalTime;
      this.isRunning = false;
    },
    stopTimer() {
      clearInterval(this.intervalId);
      this.isRunning = false;
    },
    performAction() {
      // Realiza la acción deseada cuando se alcanza cierto tiempo
      console.log('¡Tiempo alcanzado! Realizar acción...');
    }
  },
  /*  beforeDestroy() {
      // Clear the interval and remove the counter value from localStorage
      clearInterval(this.interval);
      localStorage.removeItem('counter');
    },*/
}


</script>
<style>
/*@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,500;1,900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,400;0,500;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:ital,wght@0,400;0,500;1,900&family=Sanchez&display=swap');

.b-form-radio + .b-form-radio {
  margin-top: 1rem;
}

.espacio-contador {
  display: block;
}

.container-contador-home {
  position: relative;
}

.container-contador {
  position: fixed;
  z-index: 9999;
  padding: 10px;
  top: 6.9vh;
  right: 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  padding: 100px;
}

.fixed-div {
  position: fixed;
  top: 75px;
  left: 250px;
  background-color: red;
  padding: 10px;

}

.fixedEdit-div {
  position: fixed;
  top: 75px;
  left: 250px;

  padding: 10px;

}

.float {
  position: fixed;
  width: 160px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  /*background-color: #952f81;*/
  background-color: #952f81;
  color: #FFF;
  border-radius: 5px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}
.multiline-content {
  white-space: pre-line;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}
.bordered-col {
  border: 1px solid #ccc;
  padding: 10px; /* Opcional: Añade espaciado interno para que el contenido no quede pegado al borde */
}
.column {
  border: 1px solid #ddd;
  background-color: #f0f0f0;
  padding: 10px;
  margin: 10px;
  width: 250px;
}
</style>